.menu-icon {
  display: none;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  z-index: 100;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
  z-index: 100;
}

.nav-elements ul a.active {
  color: #071952;
  font-weight: 500;
  position: relative;
}

.nav-elements ul li a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #071952;
}

.nav-elements ul li:last-child a.active::after {
  content: none;
}
.title-with-underline-animation {
  background-image: linear-gradient(to right, #095fad, #095fad 50%, #000 50%);
  background-size: 200% 100%;
  background-position: 100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.4s ease-in-out;
  z-index: 10;
}
.title-with-underline-animation:hover {
  background-position: 0;
  z-index: 10;
}
.title-with-underline-animation:before {
  content: "";
  background: #095fad;
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.4s ease-in-out;
  z-index: 10;
}
.title-with-underline-animation:hover::before {
  width: 100%;
  z-index: 10;
}

@media (max-width: 889px) {
  .menu-icon {
    display: block;
    cursor: pointer;
    max-height: 60px;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #fef7e5;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.5s ease-in;
    overflow: hidden;
    z-index: 150;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}
